import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"

const StyledVideoTestimonial = styled.section`
    ${baseGridStyles}
 
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            margin: 4rem 0;
            grid-column-start: 2;
            grid-column-end: 5;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            margin: 6rem auto;
            grid-column-start: 2;
            grid-column-end: 5;
        }

    figure {
        grid-column: span 3;
        grid-column-start: 1;
        grid-column-end: 5;
        display: flex;
        justify-content: center;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 3;
            grid-column-end: 5;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column-start: 3;
            grid-column-end: 6;
        }

        
    }

    div {
        grid-column: span 4;
        text-align: center;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column-start: 2;
            grid-column-end: 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 6 / 11;
            text-align: left;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 6 / 11;
            text-align: left;
        }

        h2{
            margin-bottom: 0.8rem;
            margin-top: 0;
            @media screen and (min-width: ${breakpoints.md}px) {
                margin-top: 0;
            }
        }
        
        p{
            margin-top: 1.6rem;
            @media screen and (min-width: ${breakpoints.md}px) {
                display: flex;
                padding-left: 0;
            }
            a {
                @media screen and (min-width: ${breakpoints.md}px) {
                    background-color: var(--white);
                    color: var(--light-purple-dark)!important;
                    justify-content: left;
                    padding-left: 0;
                }
            }
        }

        img {
            background-color: #ede7f6;
        }
    }
`
const StyledVideoTestimonialArticle = styled.article`
    ${baseGridStyles}
    .repsonsive-video {
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
        }

    }

    a.download-audio {
        padding: 1rem;
        border: 1px solid var(--light-purple-darkest);
        grid-column: 1 / 6;
        margin-left: 0rem;
        margin-top: 1rem;
        width: 100%;
        color: var(--light-purple-darkest);
        
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 2 / 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 5;
            margin-left: 0rem;
            margin-top: 2.4rem;
            width: 100%;
        }


        svg {
            margin-right: 1rem;
            path { 
                fill: var(--light-purple-darkest);
            }
        }
    }

    button {
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 1;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 6 / 11;
            margin-left: 1.6rem;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 6 / 11;
        }
        &.watchlisten-more {
            grid-column: 1 / 6;
            margin-left: 0rem;
            margin-top: 0rem;
            width: 100%;
            
            @media screen and (min-width: ${breakpoints.sm}px) {
                grid-column: 2 / 6;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
                grid-column: 9 / 11;
                margin-left: 0rem;
                margin-top: 2.4rem;
                width: 100%;
            }
        }
    }

    blockquote {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 2 / 12;
        }
        .single-quote {
            @media screen and (min-width: ${breakpoints.md}px) {
                margin: 0 auto;
                width: 100%;
            }
        }
    }

`
const StyledVideoTestimonialHeader = styled.header`
    grid-column: span 4;

    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 2 / 12;
    }


    h1 {
        text-align: center;
        abbr {
            text-decoration: none;
        }
    }
    h2 {
        text-align: center;
    }
`
const StyledVideoTestimonialVideo = styled.div`
    grid-column: span 4;
    ${baseGridStyles}
    padding: 0;
    margin: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
        margin: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
        margin: 0 0 2.4rem;
    }

    &.replay {
        margin-top: 4rem;   
    }

    > div {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: 1 / 7;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
        }

        &.react-player {
            grid-column: span 5;
            @media screen and (min-width: ${breakpoints.sm}px) {
               grid-column: 1 / 7;
            }
            @media screen and (min-width: ${breakpoints.md}px) {
               grid-column: 3 / 11;
            }
        }
    }
    h3 {
        grid-column: span 4;
        display: block;
        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 4 / 11;
            margin-left: 1.6rem;
            text-align: center;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 5 / 14;
            margin-left: 1.6rem;
            text-align: center;
        }
    }
`

const StyledVideoTestimonialContent = styled.div`
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: 2 / 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        grid-column: 4 / 10;
    }
    p{
        margin-top: 1.6rem;
    }
`

const StyledVideoTestimonialLede = styled.p`
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        text-align: center;
    }
    
`


export {
    StyledVideoTestimonial,
    StyledVideoTestimonialArticle,
    StyledVideoTestimonialHeader,
    StyledVideoTestimonialVideo,
    StyledVideoTestimonialLede,
    StyledVideoTestimonialContent
} 