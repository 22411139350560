import React, { useEffect, useState } from "react"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import {
  StyledProductList,
  StyledProductListItem,
} from "../components/Products/productArchive"
import PrimaryProductCard from "./Products/PrimaryProductCard"
import PermissionBonusCard from "./Products/PermissionBonusCard"
import PermissionResetNervousSystem from "./Products/PermissionResetNervousSystem"
import PermissioningPractitioner from "./Products/PermissioningPractitioner"
import PermissioningManifestingCard from "./Products/PermissioningManifesting"
import SpoonTherapy from "./Products/SpoonTherapy"
import CommandHealing from "./Products/CommandHealing"
import FtmjHealingTuningBlueprint from "./Products/FmtjHealingTuningBlueprint"
import MorningDailyIntention from "./Products/MorningDailyIntention"
import PermissionResetNervousSystemJuly2023Workshop from "./Products/PermissionResetNervousSystemJuly2023Workshop"

const ProductList = ({ posts, pageType, padding, type }) => {
  const [countryCode, setCountryCode] = useState("") // store token
  const identity = useIdentityContext()
  useEffect(() => {
    fetch('/geolocation',{
    headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
    })
    .then(function(response){
        return response.json();
    })
    .then(function(geoLocationData) {        
        setCountryCode(geoLocationData?.geo?.country?.code)
    })
}, [])
  return (
    
    <StyledProductList pageType={pageType} padding={padding}>
      {posts.map((post, index) => {
          return(
            <>
              {!post.node.productData.hideonarchive &&
                <StyledProductListItem key={post.node.id} type={type}>
                  <PrimaryProductCard
                      title={post.node.title}
                      id={post.node.id}
                      uri={post.node.uri}
                      excerpt={post.node.excerpt}
                      featuredImage={post.node.featuredImage}
                      pricefull={countryCode === 'US' ? post.node.productData.pricefullusd : post.node.productData.pricefull}
                      pricesale={countryCode === 'US' ? post.node.productData.pricesaleusd : post.node.productData.pricesale}
                      hidesale={post.node.productData.hidesale}
                      amazonLink={post.node.productData.linkToAmazon}
                      stripefullprice={countryCode === 'US' ? post.node.productData.stripefullpriceusd : post.node.productData.stripefullprice}
                      stripesaleprice={countryCode === 'US' ? post.node.productData.stripesalepriceusd : post.node.productData.stripesaleprice}
                      productTag={post.node.productsTag.nodes[0]?.slug}
                      currency={countryCode === 'US' ? '$' : '£'}
                    /> 
                </StyledProductListItem>
              }
            </>
          )
      })}
    </StyledProductList>
  )
}

export default ProductList
