import styled from "styled-components"
import breakpoints from "./breakpoints"
import { regular16, bold16, bold28 } from "./typography"
import { baseGridStyles } from "./base"
import { StyledButton } from "../styledComponents/button"
import { Section, SectionLede } from "../styledComponents/section"
import { StyledTabsList, StyledTab, StyledTabSelected } from "../styledComponents/tabs"

const EventListSection = styled(Section)`
  display: block;
  max-width: 100%;
  margin: 0;
  .react-tabs {
    padding: 0;
    margin-top:1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      padding: 0;
    }
  }
  .react-tabs__tab-panel {
    ol.faq {
       > li{
        p:first-of-type{
          font-weight: 700;
        }
      }
      p {
        margin-bottom: 1.6rem;
      }
    }
  }
 
  .react-tabs__tab-list {
    ${StyledTabsList}
    position: relative;
    margin-bottom: 1.6rem;
    margin-top: 0;
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      justify-content: center;
      max-width: 70vw;
      margin: 0 auto 1.6rem auto;
    }
  }

  .react-tabs__tab {
    ${StyledTab}
    margin-left:-0.8rem;
    font-weight: 700;

    &.react-tabs__tab--disabled {
      color: #dedede;
    }

  }

  .react-tabs__tab--selected {
    ${StyledTabSelected}
  }
`

const EventListSectionHeader = styled.header`
  ${baseGridStyles}
  h2 {
    text-align: center;
  }
`

const EventListSectionLede = styled(SectionLede)`
  text-align: center;
  
`

const card = { width: "288px", margin: 8, height: "14rem" }
const cardSm = { width: "558px", margin: 12, height: "22.6rem" }
const cardMd = { width: "564px", margin: 16, height: "22.6rem" }
const cardLg = { width: "752px", margin: 24, height: "22rem" }

const marginLeft = index => {
  if (index === 0) {
    return `
      margin-left: calc((100vw - (${card.width} + (2 * ${card.margin}px))) / 2);

      @media screen and  (min-width: ${breakpoints.sm}px){
        margin-left: calc((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2);
      }
      
      @media screen and  (min-width: ${breakpoints.md}px){
        margin-left: calc((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2);
      }

      @media screen and  (min-width: ${breakpoints.l}px){
        margin-left: calc((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2);
      }
    `
  } else {
    return `

    margin-left: calc(((100vw - (${card.width} + (2 * ${card.margin}px))) / 2) - (${index} * (${card.width} + (2 * ${card.margin}px))));

    @media screen and  (min-width: ${breakpoints.sm}px){
      margin-left: calc(((100vw - (${cardSm.width} + (2 * ${cardSm.margin}px))) / 2) - (${index} * (${cardSm.width} + (2 * ${cardSm.margin}px))));
    }
    
    @media screen and  (min-width: ${breakpoints.md}px){
      margin-left: calc(((100vw - (${cardMd.width} + (2 * ${cardMd.margin}px))) / 2) - (${index} * (${cardMd.width} + (2 * ${cardMd.margin}px))));
    }

    @media screen and  (min-width: ${breakpoints.l}px){
      margin-left: calc(((100vw - (${cardLg.width} + (2 * ${cardLg.margin}px))) / 2) - (${index} * (${cardLg.width} + (2 * ${cardLg.margin}px))));
    }
    `
  }
}

const EventListWrapper = styled.div`
  position: relative;
  width: 100%;
`
const EventList = styled.ul`
  background: red;
  ${props => marginLeft(props.index, props.totalEvents)};
  transition: all 0.5s ease-in-out;
  ${props =>
    props.totalEvents &&
    `width:calc(${props.totalEvents}00vw + (16px*${props.totalEvents * 2}));
      @media screen and (min-width: ${breakpoints.sm}px) {
       width:calc(${props.totalEvents}00vw)
      }
      @media screen and  (min-width: 1304px){
        width:calc(${props.totalEvents}*752px +  26.5vw + (${props.totalEvents
    } * 75px));
      }
    `};
  list-style-type: none;
  display: flex;
  padding: 0;
  margin-top: 0;
  align-items: center;
  min-height: calc(21rem - (25px + 72px));
`

const EventListItem = styled.li`
  width: ${card.width};
  margin: 0 ${card.margin}px;
  opacity: ${props => (props.isInFocus ? `1` : `0.08`)};
  transition: opacity 0.35s ease-in-out;
  min-height: calc(21rem - (25px + 72px));
  @media screen and (min-width: ${breakpoints.sm}px) {
    width: ${cardSm.width};
    margin: 0 ${cardSm.margin}px;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    width: ${cardMd.width};
    margin: 0 ${cardMd.margin}px;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    width: ${cardLg.width};
    margin: 0 ${cardLg.margin}px;
  }

  cite {
    b {
      display: block;
      ${bold16}
    }
  }
`

const EventInfo = styled.div`
  ${bold16};
  background: var(--white);
  border-left: 0;
  height: ${card.height};
  padding: 2.4rem;
  margin: 0;
  position: relative;
  display: flex;
  align-items: top;
  justify-content: center;
  ${baseGridStyles}
  @media screen and (min-width: ${breakpoints.sm}px) {
    ${bold28}
    width: 100%;
    padding: 4.8rem;
    height: ${cardSm.height};
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 5.6rem;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    height: ${cardLg.height};
  }

  &:before {
    width: 3.3rem;
    height: 2.4rem;
    top: 0;
    left: -0.8rem;
    position: absolute;
    content: "";
    display: block;
    visibility: visible;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 3.3rem 2.4rem;
    opacity: ${props => (props.isInFocus ? `1` : `0`)};
    transition: opacity 1.2s ease-in-out;
    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 7.2rem;
      height: 5.2rem;
      background-size: 7.2rem 5.2rem;
      top: 0;
      left: -1.8rem;
    }
  }
`

const EventInfoDateWrapper = styled.time`
  grid-column: 1 / 2;
  text-align: center;
  border: 1px solid var(--grey-lighter);
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 3;
    padding: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 2 / 5;
    padding: 2.4rem;
  }
`

const EventInfoDateMonth = styled.div`
  color: var(--red-mid);
  ${bold16};
  text-transform: uppercase;
`

const EventInfoDateDay = styled.div`
  
`
const EventInfoDescription = styled.div`
  grid-column: 2 / 5;
  ${regular16}
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 3 / 7;
    padding: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 5 / 13;
    padding-top: 0;
  }
`

const EventRegisterButtonWrap = styled.div`
  grid-column: span 5;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 7;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 5 / 9;
  }
`
const EventRegisterButton = styled(StyledButton)`
  
  margin: 0 auto;
  z-index: 3;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 7;
    padding: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 5 / 9;
    position: relative;
    top: -7rem;
    left: 1.6rem;
    margin: 0;
  }
`

const EventListArrows = styled.ul`
  display: none;
  @media screen and (min-width: ${breakpoints.sm}px) {
    display: flex;
    justify-content: space-between;
    z-index: 0;
    align-items: center;
    position: absolute;
    top: calc((${cardSm.height} / 2) - 41px);
    left: calc((100vw - (${cardSm.width} + 82px)) / 2);
    width: calc(${cardSm.width} + 82px);
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    top: calc((${cardMd.height} / 2) - 41px);
    left: calc((100vw - (${cardMd.width} + 82px)) / 2);
    width: calc(${cardMd.width} + 82px);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    top: calc((${cardLg.height} / 2) - 41px);
    left: calc((100vw - (${cardLg.width} + 82px)) / 2);
    width: calc(${cardLg.width} + 82px);
  }
`
const EventListArrowItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  z-index: 2;

  button {
    background: none;
    border: 0;
    outline: 0;
    position: relative;
    padding: 0;
    cursor: pointer;

    &:disabled {
      cursor: initial;
      svg {
        opacity: 0.3;
        transition: all 0.8s ease-in-out;
      }
    }
  }
`

const EventListDot = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 3;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2rem;
  }
`

const EventListItemDot = styled.li`
  margin: 0 3px;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0;
  }

  input {
    width: 1.7rem;
    height: 1.7rem;
    appearance: none;
    border-radius: 50%;
    border: 0.3rem solid var(--grey-mid);
    display: block;
    margin: 0.425rem;
    outline: none;
    cursor: pointer;

    &:checked {
      border: 0.3rem solid var(--grey-mid-dark);
      background: var(--grey-mid-dark);
    }
  }
`
export {
  EventListSection,
  EventListSectionHeader,
  EventListSectionLede,
  EventListWrapper,
  EventList,
  EventListItem,
  EventInfo,
  EventInfoDateWrapper,
  EventInfoDateMonth,
  EventInfoDateDay,
  EventInfoDescription,
  EventRegisterButtonWrap,
  EventRegisterButton,
  EventListArrows,
  EventListArrowItem,
  EventListDot,
  EventListItemDot,
}
